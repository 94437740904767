<template>
  <div>
    <page-title>Messages</page-title>
    <page-subtitle>Send Message to Clients</page-subtitle>
    <div class="mt-5">
      <div class="mt-4 bg-white">
        <div class="bg-white p-3">
          <div
            class="tabs flex flex-row space-x-3 text-gray-600 text-sm font-semibold border-b border-gray-200"
          >
            <span
              @click="changeActiveTab('email')"
              class="py-3 px-5 cursor-pointer rounded-tl"
              :class="
                activeTab === 'email' ? 'bg-primary text-white' : 'bg-white'
              "
              >Email</span
            >
            <span
              @click="changeActiveTab('sms')"
              class="py-3 px-5 cursor-pointer"
              :class="
                activeTab === 'sms' ? 'bg-primary text-white' : 'bg-white'
              "
              >SMS</span
            >
          </div>
        </div>
        <div class="p-3 px-5">
          <section-subtitle>Enter message details</section-subtitle>
          <div v-if="activeTab === 'email'">
            <!-- <div class="flex space-x-3 flex-wrap">
              <div
                class="flex items-center px-3 py-2 text-sm space-x-3 text-gray-800 bg-gray-300 rounded"
              >
                <span>Mokaiecs</span>
                <span class="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    focusable="false"
                    width="1em"
                    height="1em"
                    style="
                      -ms-transform: rotate(360deg);
                      -webkit-transform: rotate(360deg);
                      transform: rotate(360deg);
                    "
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z"
                      fill-opacity=".8"
                      fill="#626262"
                    />
                    <path
                      d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372s372-166.6 372-372s-166.6-372-372-372zm171.8 527.1c1.2 1.5 1.9 3.3 1.9 5.2c0 4.5-3.6 8-8 8l-66-.3l-99.3-118.4l-99.3 118.5l-66.1.3c-4.4 0-8-3.6-8-8c0-1.9.7-3.7 1.9-5.2L471 512.3l-130.1-155a8.32 8.32 0 0 1-1.9-5.2c0-4.5 3.6-8 8-8l66.1.3l99.3 118.4l99.4-118.5l66-.3c4.4 0 8 3.6 8 8c0 1.9-.6 3.8-1.8 5.2l-130.1 155l129.9 154.9z"
                      fill-opacity=".1"
                      fill="#626262"
                    />
                    <path
                      d="M685.8 352c0-4.4-3.6-8-8-8l-66 .3l-99.4 118.5l-99.3-118.4l-66.1-.3c-4.4 0-8 3.5-8 8c0 1.9.7 3.7 1.9 5.2l130.1 155l-130.1 154.9a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3l99.3-118.5L611.7 680l66 .3c4.4 0 8-3.5 8-8c0-1.9-.7-3.7-1.9-5.2L553.9 512.2l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"
                      fill-opacity=".8"
                      fill="#626262"
                    />
                  </svg>
                </span>
              </div>
            </div> -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Select Client</label
                >
                <v-select
                  multiple
                  class="py-1"
                  :options="clients"
                  label="name"
                  v-model="email.clientIds"
                  :reduce="client => client._id"
                ></v-select>
              </div>
              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Subject</label
                >
                <t-input
                  v-model="email.title"
                  type="text"
                  class="focus:outline-none"
                ></t-input>
              </div>
              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Message</label
                >
                <t-textarea
                  v-model="email.message"
                  type="text"
                  class="focus:outline-none"
                ></t-textarea>
              </div>
            </div>
            <div class="mt-10 flex items-center justify-end">
              <t-button
                @click="sendEmail"
                class="flex items-center justify-center"
              >
                <span v-if="!sendingEmail">Send</span>
                <spinner v-else></spinner>
              </t-button>
            </div>
          </div>
          <div v-if="activeTab === 'sms'">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Select Client</label
                >
                <v-select
                  multiple
                  class="py-1"
                  :options="clients"
                  label="name"
                  v-model="sms.clientIds"
                  :reduce="client => client._id"
                ></v-select>
              </div>

              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Message</label
                >
                <t-textarea
                  v-model="sms.message"
                  type="text"
                  class="focus:outline-none"
                ></t-textarea>
              </div>
            </div>
            <div class="mt-10 flex items-center justify-end">
              <t-button
                @click="sendSms"
                class="flex items-center justify-center"
              >
                <span v-if="!sendingSms">Send</span>
                <spinner v-else></spinner>
              </t-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle.vue'
import clientApi from '../../api/client'
import Spinner from '../../components/Utils/Spinner.vue'

export default {
  name: 'Message',
  components: { PageTitle, PageSubtitle, SectionSubtitle, Spinner },
  data: () => ({
    activeTab: 'email',
    clients: [],
    email: {},
    sms: {},
    sendingEmail: false,
    sendingSms: false
  }),
  created() {
    this.getClients()
  },
  methods: {
    async sendEmail() {
      this.sendingEmail = true
      const res = await clientApi.client().sendEmail({
        clientIds:
          this.email.clientIds.filter(e => e._id !== 'all').length > 0
            ? this.clients.map(e => e._id).filter(e => e !== 'all')
            : this.email.clientIds,
        title: this.email.title,
        message: this.email.message
      })

      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.sendingEmail = false
        return
      }

      this.sendingEmail = false
      this.email = {}

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
    },
    async sendSms() {
      this.sendingSms = true
      const res = await clientApi.client().sendSms({
        clientIds: this.sms.clientIds,
        message: this.sms.message
      })

      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.sendingSms = false
        return
      }

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })

      this.sendingSms = false
      this.sms = {}
    },
    async getClients() {
      const res = await clientApi
        .client()
        .getAll({ offset: 0, limit: 0 }, false)
      this.clients = res.data.data.map(client => ({
        ...client,
        name: client.firstName + ' ' + client.lastName
      }))
      this.clients.unshift({ _id: 'all', name: 'All Clients' })
    },
    changeActiveTab(tab) {
      this.activeTab = tab
    }
  }
}
</script>

<style></style>
